import {Loader, LoaderOptions, google} from "google-maps";

const loaderOptions: LoaderOptions = {/* todo */};

declare module 'vue/types/vue' {
  interface Vue {
    $google: google
  }
}

export default {
  async install(Vue, options) {
    const loader = new Loader('AIzaSyBefUlhmRp62R6Zs6iNYYf38DRLnIffbmM', loaderOptions)
    Vue.prototype.$google = null
    try {
      Vue.prototype.$google = await loader.load()
    } catch( e ) {
      console.log( e )
    }
  }
}